<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="1"/>
        <b-col cols="10">
          <SubHeader class="mb-2">
            <b-row class="mt-2 mb-1">
              <b-col cols="3">
                <b-form-input v-model="search" debounce="500" size="sm"
                              placeholder="Suche nach Überschrift oder Kategorie..."/>
              </b-col>
              <b-col v-if="buttonRender">
                <b-button :variant="buttonVariant" size="sm" @click="deleteNews">
                  <b-icon-trash-fill/>
                </b-button>
              </b-col>
            </b-row>
          </SubHeader>
          <b-row>
            <b-col cols="5">
              <b-row v-for="news in newsFiltered" :key="news.id" align-v="center">
                <b-col cols="1">
                  <b-form-checkbox  v-model="newsSelect" :value="news.id" inline/>
                </b-col>
                <b-col cols="11">
                  <NewsSingle class="mb-2" :news="news" @refresh="refresh"/>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1"/>
            <b-col cols="6">
              <NewsAdd :test="'test'" @refresh="refresh"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import {getRequest, putRequest} from "@/modules/requests";
import NewsAdd from "@/components/news/NewsAdd.vue";
import NewsSingle from "@/components/news/NewsSingle.vue";
import SubHeader from "@/components/structure/SubHeader.vue";
import ScrollToTop from "@/components/utility/ScrollToTop.vue";

export default {
  name: "NewsManage",
  components: {
    SubHeader,
    NewsAdd,
    NewsSingle,
    ScrollToTop
  },
  data() {
    return {
      news: null,
      newsSelect: [],
      search: ''
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getNews() {
      await getRequest('news/all', null, this)
          .then((response) => {
            this.news = response.data
          })
    },
    async deleteNews() {
      let data = {
        news_ids: this.newsSelect
      }
      await putRequest('news/delete', data, this, 'Nachrichten wurden erfolgreich entfernt.', 'Nachrichten konnten nicht entfernt werden.')
          .then(() => {
            this.newsSelect = []
            this.refresh()
          })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getNews()
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    newsFiltered() {
      return _.filter(this.news, (entry) => {
        return entry.headline.toLowerCase().includes(this.search.toLowerCase()) ||
               entry.news_category.displayname.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    buttonRender() {
      return this.newsSelect.length > 0;
    },
    buttonVariant() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
